export default [
  {
    name: "starTexture",
    type: "texture",
    path: "textures/5.png",
  },
  {
    name: "forwardTexture",
    type: "texture",
    path: "textures/forward-button.png",
  },
  {
    name: "backwardTexture",
    type: "texture",
    path: "textures/back-button.png",
  },
  {
    name: "playTexture",
    type: "texture",
    path: "textures/play-button.png",
  },
  {
    name: "pauseTexture",
    type: "texture",
    path: "textures/pause-button.png",
  },
  {
    name: "superBackTexture",
    type: "texture",
    path: "textures/super-back-button.png",
  },
  {
    name: "superForwardTexture",
    type: "texture",
    path: "textures/super-forward-button.png",
  },
  // {
  //     name: 'sampleModel',
  //     type: 'gltfModel',
  //     path: 'path/to/models/sushi.glb'
  // } ,
  {
    name: "font",
    type: "font",
    path: "helvetiker_regular.typeface.json",
  },
];
